@ -1,219 +0,0 @@
<template>
  <div class="course-form">
    <div class="row">
      <!-- Course -->
      <div class="col l2 m6 s12 dtitle">
        <strong>Course</strong>
        <select
          class="browser-default dropd"
          @change="onCourseChange($event)"
          v-model="selectedCourseId"
        >
          <option value="0" disabled selected>--Select--</option>
          <option v-for="(course, index) in courseList" :key="index" :value="course.courseId">
            {{ course.courseName }}
          </option>
        </select>
        <p class="error" v-show="showCourseError">Please select course</p>
      </div>
      <div class="col l2 m6 s12 dtitle">
        <strong>Class</strong>
        <select
          class="browser-default dropd"
          @change="onClassChange($event)"
          v-model="selectedClassId"
        >
          <option value="0" disabled selected>--Select--</option>
          <option
            v-for="(courseClass, index) in courseClassList"
            :key="index"
            :value="courseClass.classId"
          >
            {{ courseClass.className }}
          </option>
        </select>
        <p class="error" v-show="showCourseError">Please select Class</p>
      </div>
      <div class="col l2 m6 s12 dtitle">
        <strong>Subject</strong>
        <select
          class="browser-default dropd"
          @change="onSubjectChange($event)"
          v-model="selectedSubjectId"
        >
          <option value="0" disabled selected>--Select--</option>
          <option
            v-for="(courseClassSubject, index) in courseClassSubjectList"
            :key="index"
            :value="courseClassSubject.subjectId"
          >
            {{ courseClassSubject.subjectName }}
          </option>
        </select>
        <p class="error" v-show="showCourseError">Please select Subject</p>
      </div>
      <div class="col l4 m6 s12 dtitle">
        <strong>Chapter</strong>
        <select class="browser-default dropd" v-model="selectedCourseChapterId">
          <option value="0" disabled selected>--Select--</option>
          <option
            v-for="(courseClassSubjectChapter, index) in courseClassSubjectChapterList"
            :key="index"
            :value="courseClassSubjectChapter.CourseChapterId"
          >
            {{ courseClassSubjectChapter.ChapterName }}
          </option>
        </select>
        <p class="error" v-show="showCourseError">Please select Chapter</p>
      </div>
      <div class="col l2 m6 s12 dtitle">
        <button class="submit-btn" @click="submitGetLectureSlide">Submit</button>
      </div>
    </div>
    <div class="row">
      <div class="row title-bar">
        <div class="col s5 flex items-center">Title</div>
        <div class="col s5 flex items-center">Type</div>
        <div class="col s2 flex items-center">Actions</div>
      </div>
      <div class="row list" v-for="(data, index) in lectureSlideList" :key="index">
        <div class="col s5 fs-15 fw-500 flex items-center h-30">{{ data.Title }}</div>
        <div class="col s5 fs-15 fw-500 flex items-center h-30">
          {{ data.CourseChapterMaterialType }}
        </div>
        <div class="col s1">
          <button class="test-btn pointer" @click="openFile(data.DocumentUrl)">View PDF</button>
        </div>
        <div class="col s1">
          <a
            v-if="data.IsDownloadable === true"
            class="topic-attempt"
            :download="data.DocumentUrl"
            :href="data.DocumentUrl"
            >download</a
          >
        </div>
      </div>
    </div>
  </div>
  <Modal
    :show="showPdfModal"
    @close="showPdfModal = false"
    :width="'98%'"
    :maxHeightBody="'100vh'"
    :height="'94%'"
    :showCloseBtn="true"
  >
    <template v-slot:body class="assignment-modal-body">
      <iframe
        allowfullscreen="allowfullscreen"
        scrolling="no"
        class="fp-iframe"
        :src="`https://heyzine.com/api1?pdf=${pdfUrl}&fs=1&pn=1&tpl=cd88994ed4a04f3394bc56ef4e11b42c1381b2c2.pdf&k=${flipper_key}`"
        style="border: 1px solid lightgray; width: 100%; max-height: 90vh; min-height: 90vh"
      ></iframe>
    </template>
  </Modal>
</template>

<script>
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      name: "nitesh",
      selectedCourseId: 0,
      selectedClassId: 0,
      selectedSubjectId: 0,
      selectedCourseChapterId: 0,
      courseList: [],
      contentData: [],
      courseClassList: [],
      courseClassSubjectList: [],
      courseClassSubjectChapterList: [],
      lectureSlideList: [],
      showPdfModal: false,
    };
  },
  created() {
    this.$store.dispatch("showLoader", true);
    MobileAPI.getDataOfAffiliationCourses(
      {},
      (response) => {
        // debugger;
        this.courseList = response.data;
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    this.$store.dispatch("showLoader", false);
  },
  computed: {
    flipper_key() {
      return process.env.VUE_APP_FLIPPER;
    },
  },
  methods: {
    onCourseChange(event) {
      const courseId = Number(event.target.value);
      this.$store.dispatch("showLoader", true);
      MobileAPI.getcourseClassSubjectChapter(
        courseId,
        0,
        (response) => {
          // debugger;
          this.contentData = response.data;
          this.courseClassList = [
            ...new Map(
              this.contentData.map((item) => [
                item.ClassId,
                { classId: item.ClassId, className: item.ClassName },
              ]),
            ).values(),
          ];
          this.courseClassSubjectList = [];
          this.courseClassSubjectChapterList = [];
          this.selectedClassId = 0;
          this.selectedSubjectId = 0;
          this.selectedCourseChapterId = 0;
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
      this.$store.dispatch("showLoader", false);
    },
    onClassChange(event) {
      const classId = Number(event.target.value);
      const subjectList = this.contentData.filter((item) => item.ClassId === classId);
      this.courseClassSubjectList = [
        ...new Map(
          subjectList.map((item) => [
            item.SubjectId,
            { subjectId: item.SubjectId, subjectName: item.SubjectName },
          ]),
        ).values(),
      ];
      this.courseClassSubjectChapterList = [];
      this.selectedSubjectId = 0;
      this.selectedCourseChapterId = 0;
      // debugger;
    },
    onSubjectChange(event) {
      const subjectId = Number(event.target.value);
      this.courseClassSubjectChapterList = this.contentData.filter(
        (item) => item.ClassId === this.selectedClassId && item.SubjectId === subjectId,
      );
      this.selectedCourseChapterId = 0;
      // debugger;
    },
    submitGetLectureSlide() {
      const self = this;
      this.$store.dispatch("showLoader", true);
      MobileAPI.getCourseChapterMaterial(
        this.selectedCourseChapterId,
        (response) => {
          // debugger;
          self.lectureSlideList = response.data;
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
      this.$store.dispatch("showLoader", false);
    },
    openFile(file) {
      this.$store.dispatch("showLoader", true);
      this.pdfUrl = encodeURIComponent(file);
      // this.pdfUrl = file;
      this.showPdfModal = true;
      setTimeout(() => {
        this.$store.dispatch("showLoader", false);
      }, 3000);
    },
  },
};
</script>
<style scoped>
.course-heading {
  padding: 18px 0;
  border-bottom: 1px solid #e0e0e0;
}

.course-heading h1 {
  font-size: 20px;
  font-weight: bold;
  color: #575858;
}
.course-form {
  background: #fff;
  padding: 15px 5px 1px 5px;
  margin-top: 25px;
  border-radius: 5px;
}
.dtitle {
  font-size: 18px;
  color: #333333;
  margin-bottom: 5px;
}

.dropd {
  border-radius: 5px;
  height: 36px;
  margin-top: 8px;
}

.submit-btn {
  margin-top: 30px;
  padding: 8px 33px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  cursor: pointer;
  width: 115px;
  height: 35px;
}
.title-bar {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  background: #6a2f85;
  color: white;
}
</style>
